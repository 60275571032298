
.App {
  text-align: center;
 
  font-family: 'Open Sans', sans-serif;
}/*
header{
  background-color: #282c34;
  color: white;
  height: 150px;
  line-height: 150px;
  border: 1px solid #282c34;
  margin-top: 10px;;
}
header h1{
  margin: 0;
}
.infos{
  padding: 16px;
  padding-bottom: 0;
  border: 1px solid #282c34;
  border-bottom: none;
  margin: 0;
}
/*
.form{
  padding: 16px;
  border: 1px solid #282c34;
  border-top: none;
}
.form label{
  font-size: 16px;
}
.form input{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  border-color: #282c34;
  text-transform: uppercase;
}
*/

#etiquette{

}
#etiquette h2{
  color: #ffffff;
  margin-top: 8px;;
}
#etiquette > div{
  background-color: #ffffff;
  border: 1px solid black;
  display: flex;
  flex-wrap: nowrap;
  width: 388px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}
#num{
  width: 88px;
  border-right: 2px solid black;
  line-height: 80px;
  font-size: 25pt;
  font-weight: 600;
}
#line_container{
  padding-bottom: 10px;
  padding-top: 10px;
  text-transform: uppercase;
}
#linea, #lineb{
  width: 300px;
  line-height: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.one_line{
  line-height: 60px!important;
}
@media screen and (max-width: 450px) {
  #etiquette > div{
    width: 291px;
    height: 60px;
  }
  #num{
    width: 66px;
    line-height: 60px;
    font-size: 19pt;
    font-weight: 600;
  }
  #line_container{
    padding-bottom: 6px;
    padding-top: 7px;
    text-transform: uppercase;
  }
  #linea, #lineb{
    width: 225px;
    line-height: 23px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
  }
  .one_line{
    line-height: 45px!important;
  }
  
}
